import { connect, getIn } from 'formik'

/**
 * @typedef { import('formik').ErrorMessageProps } FormikErrorMessageProps
 * @typedef { import('formik').FormikContextType<any> } FormikContextType
 * @typedef { import('formik').ErrorMessage } FormikErrorMessage
 */

/**
 * Form validation messages
 * @type {preact.FunctionComponent}
 * @param {FormikErrorMessageProps & { formik: FormikContextType }} props
 * @see https://formik.org/docs/api/errormessage
 * @see https://formik.org/docs/api/connect
 * @see https://github.com/formium/formik/blob/master/packages/formik/src/ErrorMessage.tsx
 */
function ValidationMessagesImpl({
  name,
  formik,
}) {
  /** @type {boolean} */
  const touch = getIn(formik.touched, name)
  /** @type {string|undefined} */
  const error = getIn(formik.errors, name)
  /** @type {string[]|undefined} */
  const status = getIn(formik.status, name)

  return (
    <ul className="uk-list uk-list-collapse uk-text-left mp-form-errors">
      {error && touch &&
        <li className="uk-text-warning">
          {error}
        </li>
      }
      {status && status.map((message, index) =>
        <li className="uk-text-danger" key={index}>
          {message}
        </li>
      )}
    </ul>
  )
}

/** @type {FormikErrorMessage} */
export default connect(ValidationMessagesImpl)

/**
 * Parse vlucas/valitron i18n messages
 * Not used - does not make sense witout i18n library
 * @note Message params are serialized in Valitron\Validator::error method
 *       and may be cusomized by patching
 * @param {string} message - Status message
 * @param {string} name - Form name
 * @return {any}
 */
export function parseMessage(message, name) {
  // Split by first comma
  const match = message.match(/^([^,]+)(?:,(.*))$/)

  if (!match) {
    return message
  }

  const [, rule, paramsSerialized] = match

  // Arrays are in format: ['x','y'], nulls become ''
  if (rule === 'in') {
    const inParams = JSON.parse(paramsSerialized.replaceAll("'", '"'))

    return `Should be one of: ${inParams.join(', ')}`
  }

  return message
}
