import { useMemo } from 'preact/hooks'

import SCREEN_TYPE from './screenType.constants.js'
import Icon from '../Icon/Icon.jsx'

/**
 * Screen type label component
 * @type {preact.FunctionComponent<{
 *   type: SCREEN_TYPE|null,
 * }>}
 */
const ScreenType = ({ type }) => {

  /** @type {{label: string, icon: string} | null} */
  const atrribs = useMemo(() => {
    switch (type) {
      case SCREEN_TYPE.MYPROMO_PRESENTER:
        return { label: 'Presenter', icon: 'tv' }
      case SCREEN_TYPE.EMAG_LEDMATRIX:
        return { label:'LEDMatrix', icon: 'phone-landscape' }
      default:
        return null
    }
  }, [type])

  if (!atrribs) {
    return null
  }

  return (
    <div className="uk-text-nowrap">
      <Icon icon={atrribs.icon} />
      <span className="uk-margin-small-left">
        {atrribs.label}
      </span>
    </div>
  )
}

export default ScreenType
