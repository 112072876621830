import { Link, useLocation } from 'wouter-preact'

import routes from '../../routes.js'

/**
 * Not found page
 *
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {{path: string}} [props.params] - Route params
 */
const NotFound = ({ params }) => {
  const [ location ] = useLocation()

  // Trim slash from beginning
  const path = location.replace(/^\/(.*)/, '$1')

  return (
    <section className="uk-article">
      <h1 className="uk-article-title">
        {'404: Not Found'}
      </h1>
      <p>
        {`The '${path}' route is gone :(`}
      </p>
      <p>
        <Link
          href={routes.dashboard}
          className="uk-link"
        >
          {'Navigate to Dashboard'}
        </Link>
      </p>
    </section>
  )
}

export default NotFound
