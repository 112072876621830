/**
 * @typedef { import('preact/hooks').Dispatch<number> } PageIndexStateUpdater
 */

/**
 * Simple table pagination
 *
 * @type {preact.FunctionComponent<{
 *   pageIndex: number,
 *   pageItems: number,
 *   paginationLimit: number,
 *   setPageIndex: PageIndexStateUpdater,
 * }>}
 * @see https://getuikit.com/docs/pagination
 */
const Pagination = ({
  pageIndex,
  pageItems,
  paginationLimit,
  setPageIndex,
}) =>
  <nav aria-label="Pagination">
    <ul className="uk-pagination uk-flex-center uk-margin-remove">
      {/** Previous */}
      <li className={pageIndex === 0 ? 'uk-disabled' : ''}>
        <button
          type="button"
          className="uk-icon-link"
          data-uk-pagination-previous
          data-uk-tooltip="title: Previous"
          onClick={() => setPageIndex(pageIndex - 1)}
        ></button>
      </li>
      {/** Current */}
      <li className="uk-active">
        <span aria-current="page">{pageIndex + 1}</span>
      </li>
      {/*
      <li className="uk-active">
        <input
          className="uk-input uk-form-small uk-form-width-xsmall"
          type="number"
          value={pageIndex + 1}
          min="0"
          step="1"
          aria-label="Input"
          onChange={(event) => setPageIndex(event.target.valueAsNumber - 1) }
        />
      </li>
      */}
      {/** Next */}
      <li className={pageItems < paginationLimit ? 'uk-disabled' : ''}>
        <button
          type="button"
          className="uk-icon-link"
          data-uk-pagination-next
          data-uk-tooltip="title: Next"
          onClick={() => setPageIndex(pageIndex + 1)}
        ></button>
      </li>
    </ul>
  </nav>

export default Pagination

/**
 * Get pagination query
 * @param {number} pageIndex
 * @param {number} [paginationLimit] - Items per page, defaults to limit on server
 * @return {URLSearchParams}
 */
export function getPaginationQuery(pageIndex, paginationLimit = 20) {
  const urlSearchParams = new URLSearchParams()

  if (pageIndex !== 0) {
    urlSearchParams.set('offset', (pageIndex * paginationLimit).toFixed())
  }

  if (paginationLimit !== 20) {
    urlSearchParams.set('limit', paginationLimit.toFixed())
  }

  return urlSearchParams
}
