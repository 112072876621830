import { Redirect } from 'wouter-preact'
import { useSWRConfig } from 'swr'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'

/**
 * Sign out page
 *
 * @type {preact.FunctionComponent}
 */
export default function SignOut() {
  const { signout } = useAuth()
  const { mutate: globalMutate } = useSWRConfig()

  signout()

  // Clear SWR cache
  // See https://github.com/vercel/swr/issues/1887#issuecomment-1171269211
  globalMutate(() => true, undefined, false)

  return (
    <Redirect
      to={routes.dashboard}
      replace={true}
    />
  )
}
