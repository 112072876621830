import { useField } from 'formik'

import { useOrganisations } from '../../Context/Organisations.jsx'
import ValidationMessages from './ValidationMessages.jsx'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Organisation> } OrganisationIndexResponse
 */

/**
 * Organisation selector
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {string} props.name
 * @param {boolean} [props.disabled]
 * @param {preact.JSX.Element} [props.Icon]
 * @example
 * ```jsx
 * <OrganisationSelect name="organisationId" />
 * ```
 */
function OrganisationSelect ({ disabled = false, Icon = undefined, ...formikProps }) {
  const {
    items: organisations,
    isLoading,
  } = useOrganisations()

  const [field] = useField(formikProps)

  return (
    <>
      <select
        className="uk-select uk-form-width-medium"
        disabled={disabled || isLoading}
        {...field}
      >
        <option value="">
          {'- None -'}
        </option>
        {organisations.map(organisation =>
          <option value={organisation.id} key={organisation.id}>
            {organisation.name}
          </option>
        )}
      </select>
      {Icon}
      <ValidationMessages name={field.name} />
    </>
  )
}

export default OrganisationSelect
