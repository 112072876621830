import { useState, useEffect, useRef, useMemo } from 'preact/hooks'
import { html } from 'htm/preact'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import Thumbnail from './Image/Thumbnail.js'
import Placeholder from './Media/Placeholder.js'
import Manager from './Image/Manager.js'
import { blobRegExp } from './Image/utils.js'

import Dialog from '../Dialog.js'

import { useUniqueId } from '../../Hooks/uid.js'

import {
  formControlInvalid,
  createFormControlReportValidity,
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLInputElement> } HTMLInputRef
 */

/**
 * @typedef { import('./Image/Thumbnail.js').eventListener } imageEventListener
 */

/**
 * @typedef {Object} Options
 * @property {string} url
 * @property {string|null} fit
 * @property {number} offset
 * @property {string|null} bgColor
 */

/**
 * @typedef {Object} Config
 * @property {string} apiUrl
 * @property {string} apiBearerToken
 */

/**
 * @typedef {Object} AvailableItem
 * @property {string} url
 * @property {string} mime
 * @property {number} size
 * @property {number} timestamp
 * @property {number} width
 * @property {number} height
 * @property {boolean} isWorking
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/** @type {Array<{value: string, label: string}>} */
const optionFitOptions = [
  { value: null, label: 'default' },
  { value: 'contain', label: 'contain' },
  { value: 'cover', label: 'cover' }, // Crop to contain
  { value: 'fill', label: 'fill' }, // Stretch to fit
]

/**
 * @typedef {string|null} BackgroundColor
 * @enum {BackgroundColor}
 */
const BACKGORUND_COLOR = {
  DEFAULT: null,
  FALLBACK: '#000000',
}

/** @type {Array<{value: string, label: string}>} - Predefined colors */
const optionBgColorOptions = [
  { value: BACKGORUND_COLOR.FALLBACK, label: 'Black' },
  { value: '#bb2557', label: 'Magenta' },
  { value: '#ffffff', label: 'White' },
]

/**
 * Image
 * Note: consider [UIKit upload component](https://getuikit.com/docs/upload)
 * @param {ComponentProps} props
 */
export default function ImageOption ({
  options = {
    url: null,
    fit: null,
    offset: 0,
    bgColor: BACKGORUND_COLOR.DEFAULT,
  },
  config = {
    apiUrl: undefined,
    apiBearerToken: undefined,
  },
  setReportValidity,
  onChange,
}) {
  /** @type {ReturnType<typeof useState<AvailableItem[]>>} - Available items */
  const [availableItems, setAvailableItems] = useState([])

  const [previewSrc, setPreviewSrc] = useState(null)
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)

  /** @type {HTMLInputRef} */
  const inputRefBgColor = useRef()

  const translator = useTranslator()

  /** @var {string} */
  const authorization = useMemo(() => config.apiBearerToken && `Bearer ${config.apiBearerToken}`, [config.apiBearerToken])

  const datalistId = `bip-options-image-background-color-datalist-${useUniqueId()}`

  // Fetch available items
  useEffect(() => {
    if (!config.apiUrl) {
      console.error('Image: Please set apiUrl in config')

      // Reset available items
      availableItems.length && setAvailableItems([])

      return
    }

    fetch(config.apiUrl, {
      method: 'GET',
      headers: { authorization },
    })
      .then(response => response.json())
      .then((/** @type {AvailableItem[]} */availableItems) =>
        setAvailableItems(availableItems.sort((itemA, itemB) =>
          itemB.timestamp - itemA.timestamp
        ))
      ).catch(() => {})
  }, [config.apiUrl])

  // Flag preview as loding on every change
  useEffect(() => {
    setPreviewSrc(options.url)
    setIsPreviewLoading(options.url !== null)
  }, [options.url])

  useEffect(() => {
    setReportValidity(
      createFormControlReportValidity(inputRefBgColor.current)
    )
  }, [])

  /**
   * Handle file change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {Promise<void>}
   */
  const handleOptionFileChange = async event => {
    /** @type {File|null} */
    const file = event.target.files.item(0)

    // User pressed cancel in Choose image modal
    if (!file) {
      return
    }

    // Make sure mime type is an image
    if (!file.type.startsWith('image/')) {
      event.target.value = ''

      return
    }

    const previewSrc = URL.createObjectURL(file)

    const imageHelper = new Image()
    const imageHelperAc = new AbortController()

    // Note: Must load image before preview src is revoked (net::ERR_FILE_NOT_FOUND)
    try {
      await new Promise((resolve, reject) => {
        imageHelper.addEventListener('load', resolve, { signal: imageHelperAc.signal } )
        imageHelper.addEventListener('error', reject, { signal: imageHelperAc.signal } )
        imageHelper.setAttribute('src', previewSrc)
      })
    } catch (error) {
      // No-op
    } finally {
      // Remove listeners
      imageHelperAc.abort()
    }

    /** @type {AvailableItem} */
    const availableItem = {
      url: previewSrc,
      mime: file.type,
      size: file.size,
      timestamp: Date.now() / 1e3,
      // Note: may be 0 on error
      width: imageHelper.naturalWidth,
      height: imageHelper.naturalHeight,
      isWorking: true,
    }

    const updatedAvailableItems = [ { ...availableItem }, ...availableItems ]

    setAvailableItems(updatedAvailableItems)

    // Note: when using library mode, there is no way to determine that user wants to replace existing item
    // Do not allow updates
    const isUpdate = false

    /** @type {boolean} - Is create or update */
    // const isUpdate = !!availableItems.find(availableItem => availableItem.url === options.url)

    /** @type {Response} */
    let apiResponse

    // Create image on CDN
    // Note: Headers (content-type, content-length) are set by fetch
    try {
      /** @throws {Error} */
      apiResponse = await fetch(isUpdate ? options.url : config.apiUrl, {
        method: isUpdate ? 'PUT' : 'POST',
        headers: { authorization },
        body: file,
      })

      // Manually throw an error
      if (!apiResponse.ok) {
        throw new TypeError('Invalid response')
      }
    } catch (error) {
      // TODO: indicate problem (Cannot create/ update)
      // Remove from available items
      setAvailableItems(updatedAvailableItems.filter(availableItem =>
        availableItem.url !== previewSrc
      ))

      return
    } finally {
      // Reset files so change event may be triggered again
      event.target.value = ''
    }

    /** @type {string} */
    const url = apiResponse.headers.get('Location')

    /** @throws {SyntaxError} */
    // const url = await apiResponse.json()

    // Update progress flag
    setAvailableItems(updatedAvailableItems.map(availableItem =>
      availableItem.url === previewSrc
        ? { ...availableItem, url, isWorking: false }
        : availableItem
    ))
  }

  /**
   * Handle thumbnail click
   * @param {string} url
   * @return {void}
   */
  const handleSelectAvailableItem = url =>
    onChange({
      ...options,
      url,
    })

  /**
   * Handle offset change
   * @param {Object} event
   * @return {void}
   */
  const handleOffsetOptionChange = event =>
    onChange({
      ...options,
      offset: Number.isNaN(event.target.valueAsNumber)
        ? options.offset
        : event.target.valueAsNumber
    })

  /**
   * Handle remove button click
   * Note: Use soft-delete in backend implementation, so:
   *       - Playlist state is broken in case user wouldn't save playlist
   *       - Devices that didn' have chance to update playlist may still use resource
   * @param {string} url
   * @return {Promise<void>}
   */
  const handleRemoveButtonClick = async (url) => {
    const updatedAvailableItems = availableItems.map(availableItem =>
      availableItem.url === url
        ? { ...availableItem, isWorking: true }
        : availableItem
    )

    setAvailableItems(updatedAvailableItems)

    // Remove via API
    try {
      const apiResponse = await fetch(url, {
        method: 'DELETE',
        headers: { authorization },
      })

      if (!apiResponse.ok) {
        throw new TypeError('Invalid response')
      }
    } catch (error) {
      // TODO: indicate problem (Cannot delete)
      setAvailableItems(updatedAvailableItems.map(availableItem =>
        availableItem.url === url
          ? { ...availableItem, isWorking: false }
          : availableItem
      ))

      return
    }

    // Remove from thumbnails
    setAvailableItems(availableItems.filter(availableItem => availableItem.url !== url))

    if (url === options.url) {
      onChange({
        ...options,
        url: null,
      })
    }
  }

  /** @type {imageEventListener} */
  const handleImageLoad = src => {
    // Preview
    src === options.url && setIsPreviewLoading(false)

    // Revoke object URL
    blobRegExp.test(src) && URL.revokeObjectURL(src)
  }

  /**
   * Handle fit change
   * @param {Object} event
   * @param {HTMLSelectElement} event.target
   * @return {void}
   */
  const handleFitChange = ({ target }) =>
    onChange({
      ...options,
      fit: target.value ? target.value : null
    })

  /**
   * Handle background color default toggle
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleBgColorChangeDefault = event =>
    onChange({
      ...options,
      bgColor: event.target.checked
        ? BACKGORUND_COLOR.DEFAULT
        : BACKGORUND_COLOR.FALLBACK
    })

  /**
   * Handle background color change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleBgColorChange = event =>
    onChange({
      ...options,
      bgColor: event.target.value
    })

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.image.field.image.text')}
        </label>
        <div className="uk-form-controls">
          <div className="uk-card uk-card-default uk-margin-small">
            <!-- Preview -->
            <div className="uk-card-media-top uk-height-small  bip-card-media-top">
              ${options.url
                ? Thumbnail({ src: previewSrc, width: null, onLoad: handleImageLoad })
                : Placeholder()
              }
              <div
                className="uk-overlay-primary uk-position-cover"
                hidden=${!isPreviewLoading}
              >
                <div
                  className="uk-position-center"
                  data-uk-spinner="ratio: 2"
                ></div>
              </div>
            </div>
            <!-- Actions -->
            <div className="uk-card-footer uk-padding-small">
              <div className="uk-button-group">
                <!-- Update -->
                <${Dialog}
                  title=${translator.translate('option.image.lightbox.title')}
                  button=${{
                    title: translator.translate('option.image.action.change.hint'),
                    text: translator.translate('option.image.action.change.text'),
                    icon: 'pencil',
                    disabled: !config.apiUrl,
                  }}
                  footer=${html`
                    <div
                      data-uk-form-custom
                      className="uk-margin-small-right"
                    >
                      <input
                        className="uk-input"
                        type="file"
                        name="file"
                        accept="image/*"
                        onChange=${handleOptionFileChange}
                        tabindex="-1"
                        title=${translator.translate('option.image.action.upload.text')}
                      />
                      <button
                        className="uk-button uk-button-primary uk-button-small"
                        type="button"
                      >
                        <span
                          className="uk-icon"
                          data-uk-icon="icon: cloud-upload"
                        ></span>
                        <span className="uk-text-middle uk-margin-small-left">
                          ${translator.translate('option.image.action.upload.text')}
                        </span>
                      </button>
                    </div>
                  `}
                  showConfirm=${ false }
                  xonSubmit=${ handleSelectAvailableItem }
                >
                  <${Manager}
                    items=${availableItems}
                    selectedUrl=${options.url}
                    onSelectButtonClick=${handleSelectAvailableItem}
                    onRemoveButtonClick=${handleRemoveButtonClick}
                  />
                </${Dialog}>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fit -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.image.field.fit.text')}
        </label>
        <div className="uk-form-controls">
          <select
            className="uk-select uk-form-small uk-form-width-medium"
            value=${options.fit}
            onChange=${handleFitChange}
          >
            ${optionFitOptions.map(({value, label}) => html`
              <option value=${value}>
                ${translator.translate(`option.image.field.fit.option.${label}`)}
              </option>
            `)}
          </select>
        </div>
      </div>
      <!-- Offset -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.image.field.offset.text')}
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input uk-form-small uk-form-width-xsmall uk-text-right"
            type="number"
            value=${options.offset || 0}
            min="0"
            max="50"
            step="1"
            inputmode="numeric"
            onChange=${handleOffsetOptionChange}
          />${' %'}
        </div>
      </div>
      <!-- Backgorund color -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.image.field.backgroundColor.text')}
        </label>
        <div className="uk-form-controls uk-form-controls-text">
          <label>
            <input
              className="uk-checkbox uk-margin-small-right"
              type="checkbox"
              checked=${options.bgColor == BACKGORUND_COLOR.DEFAULT}
              onChange=${handleBgColorChangeDefault}
            />
            ${translator.translate('common.default.text')}
          </label>
        </div>
        <div
          className="uk-form-controls uk-margin-small-top"
          hidden=${options.bgColor == BACKGORUND_COLOR.DEFAULT}
        >
          <input
            ref=${inputRefBgColor}
            className=${classNames('uk-input uk-form-small uk-form-width-small bip-color-picker', {
              'uk-form-danger': formControlInvalid(inputRefBgColor.current),
            })}
            type="color"
            value=${options.bgColor == BACKGORUND_COLOR.DEFAULT ? BACKGORUND_COLOR.FALLBACK : options.bgColor}
            pattern="#[0-9a-f]{6}"
            list=${datalistId}
            onChange=${handleBgColorChange}
          />
          <datalist id=${datalistId}>
            ${optionBgColorOptions.map(({value, label}) => html`
              <option value=${value}>
                ${label}
              </option>
            `)}
          </datalist>
        </div>
      </div>
    </fieldset>
  `
}
