/**
 * Third-party packages config provider
 */

import { useContext } from 'preact/hooks'
import { createContext } from 'preact'

/**
 * @typedef {{ [K in keyof T]: T[K] | null }} Nullable<T>
 * @template {Record<string, string>} T
 * @note This type works only with strictNullChecks: true
 */

/**
 * @typedef {Object} PackagesConfig
 * @property {string} imageServerUrl
 * @property {string} videoServerUrl
 */

/**
 * @typedef {Nullable<PackagesConfig>} CompiledConfig
 */

/**
 * @callback CompileConfig
 * @param {App.Entity.Organisation|undefined} organisation
 * @return {Nullable<PackagesConfig>}
 */

/**
 * Context object
 */
export const PackagesConfigContext = createContext(/** @type {CompileConfig|null} */ (null))

PackagesConfigContext.displayName = 'PackagesConfigContext'

/**
 * Hook
 * @return {CompileConfig}
 */
export function usePackagesConfig () {
  const value = useContext(PackagesConfigContext)

  if (!value) {
    throw new Error('Packages Config Context has not been provided')
  }

  return value
}

/**
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {PackagesConfig} props.config
 * @param {preact.ComponentChildren} props.children
 */
export function PackagesConfigProvider ({
  config,
  children,
}) {
  /** @type {CompileConfig} */
  const compiledConfig = (organisation) => ({
    imageServerUrl: organisation ? config.imageServerUrl.replace(':organisationHash', organisation.hash) : null,
    videoServerUrl: organisation ? config.videoServerUrl.replace(':organisationHash', organisation.hash) : null,
  })

  return (
    <PackagesConfigContext.Provider value={ compiledConfig }>
      { children }
    </PackagesConfigContext.Provider>
  )
}
