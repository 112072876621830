import SCREEN_TYPE from '../../Components/ScreenType/screenType.constants.js'
import ENVIRONMENT from '../../Components/Environment/environment.constants.js'
import errorToStatusFactory from '../../Components/Form/errorToStatus.js'

/** @type {App.Entity.ScreenCreate} */
export const initialData = {
  id: null,
  pinCode: null,
  label: '',
  organisationId: null,
  type: null,
  playlistIds: [],
  geoCoords: null,
  config: {},
  specs: {},
  environment: ENVIRONMENT.PRODUCTION,
  version: null,
  heartbeat: null,
}

export const getStatusFromError = errorToStatusFactory(initialData)

/**
 * @param {Object} values
 * @return {Omit<App.Entity.ScreenCreate, 'id' | 'version' | 'heartbeat'>}
 */
export function transformValues(values) {
  return {
    pinCode: values.pinCode,
    label: values.label,
    organisationId: values.organisationId ? Number.parseInt(values.organisationId) : null,
    type: values.type ? Number.parseInt(values.type) : null,
    playlistIds: values.playlistIds.map(id => Number.parseInt(id)),
    geoCoords: values.geoCoords && values.geoCoords.every(value => value !== '')
      ? values.geoCoords.map(value => Number.parseFloat(value))
      : null,
    config: {},
    specs: values.specs,
    environment: values.environment,
  }
}

/**
 * @param {Object} values
 * @param {boolean} isNew
 * @return {Object<string, string>}
 */
export function validate(values, isNew = true) {
  /** @type {Object<string, string>} */
  const errors = {}
  const item = transformValues(values)

  if (isNew && !values.pinCode) {
    errors.pinCode = 'required'
  }

  if (!item.label) {
    errors.label = 'required'
  }

  if (
    item.type !== SCREEN_TYPE.MYPROMO_PRESENTER &&
    item.type !== SCREEN_TYPE.EMAG_LEDMATRIX &&
    item.type !== null
  ) {
    errors.type = 'in'
  }

  if (!item.organisationId) {
    errors.organisationId = 'required'
  }

  if (!item.specs) {
    errors.specs = 'invalid schema'
  }

  // TODO: Geo coords

  if (
    item.environment !== ENVIRONMENT.PRODUCTION &&
    item.environment !== ENVIRONMENT.STAGING
  ) {
    errors.environment = 'in'
  }

  return errors
}
