import SCREEN_PERMISSION from '../Pages/Screen/screen.permission.js'
import PLAYLIST_PERMISSION from '../Pages/Playlist/playlist.permission.js'
import ORGANISATION_PERMISSION from '../Pages/Organisation/organisation.permission.js'
import USER_PERMISSION from '../Pages/User/user.permission.js'
import PROFILE_PERMISSION from '../Pages/Profile/profile.permission.js'

export const SCREEN = SCREEN_PERMISSION
export const PLAYLIST = PLAYLIST_PERMISSION
export const ORGANISATION = ORGANISATION_PERMISSION
export const USER = USER_PERMISSION
export const PROFILE = PROFILE_PERMISSION

/** @typedef {string} Permission */

/** @enum {Permission} */
export const GLOBAL_CONTEXT = {
  ACCESS: 'global-context:access'
}

/** @enum {Permission} */
export const AUTH = {
  SIGN_IN_AS: 'auth:sign-in-as',
}

/** @enum {Object<string, Permission>} */
const PERMISSION = {
  SCREEN,
  PLAYLIST,
  ORGANISATION,
  USER,
  PROFILE,
  GLOBAL_CONTEXT,
  AUTH,
}

export default PERMISSION
