import { useEffect, useState } from 'preact/hooks'
import { Link, useLocation } from 'wouter-preact'
import { useSWRConfig } from 'swr'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from '../User/user.permission.js'
import { GLOBAL_CONTEXT, AUTH as PERMISSION_AUTH } from '../../Constants/permission.js'

import {
  useIndex,
  useDelete,
} from '../../Hooks/Api/useRestApi.js'
import { useSignInAs } from '../../Hooks/Api/useAuthApi.js'
import { useOrganisations } from '../../Context/Organisations.jsx'

import State from '../../Components/State/State.jsx'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import Icon from '../../Components/Icon/Icon.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'
import Pagination, { getPaginationQuery } from '../../Components/Pagination/Pagination.jsx'

import { confirm } from '../../utils/modal.js'

import styles from './users.module.css'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.User> } UserIndexResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').DeleteResponse<App.Entity.User> } UserDeleteResponse
 */

/** @type {App.Entity.User[]} */
const initialData = []

/** @type {number} */
const paginationLimit = 20

/**
 * Users collection page
 *
 * @type {preact.FunctionComponent}
 */
function UsersIndex() {
  const [ , navigate ] = useLocation()

  const [ pageIndex, setPageIndex ] = useState(0)

  /** @type {UserIndexResponse} */
  const { items = initialData, isLoading, error: loadingError, mutate } = useIndex(`/users?${getPaginationQuery(pageIndex, paginationLimit)}`)

  const { find: findOrganisation } = useOrganisations()

  /** @type {UserDeleteResponse} */
  const { isDeleting, deleter } = useDelete('/users', undefined)

  const { user, authenticate, isGranted } = useAuth()

  const { data: authResponse, isLoading: isAuthLoading, trigger: signInAs } = useSignInAs()

  const { mutate: globalMutate } = useSWRConfig()

  // Handle sign in as auth response
  useEffect(() => {
    if (!authResponse) {
      return
    }

    // Clear SWR cache
    globalMutate(() => true, undefined, false)

    // Note: No need to sign out
    authenticate(authResponse)

    // Redirect to dashboard, as target user might not have access to this page (may check if granted PERMISSION.INDEX)
    navigate(routes.dashboard)
  }, [authResponse])

  /**
   * @param {App.Entity.User} item
   */
  function handleSignInAsClick(item) {
    !isAuthLoading && signInAs(item.id)
  }

  /**
   * @param {App.Entity.User} item
   */
  async function handleDeleteClick(item) {
    if (isDeleting) {
      return
    }

    if (await confirm()) {
      return
    }

    // Note: json-server returns an empty object
    // Note: Should remove single item from cache
    await deleter(item)
    await mutate((/** @type {App.Entity.User[]} */ cachedItems) =>
      cachedItems && cachedItems.filter(cachedItem => cachedItem.id !== item.id)
    )
  }

  return (
    <section className={`uk-article ${styles.users}`}>

      {/** Breadcrumbs */}
      <Breadcrumb items={[{ text: 'Users' }]} />

      <div className="uk-card uk-card-default uk-card-small">
        <div className="uk-card-header uk-flex uk-flex-between">
          <h1 className="uk-card-title uk-margin-remove">
            {'Users'}
          </h1>

          {/** Actions */}
          <ul className="uk-iconnav">
            {isGranted(PERMISSION.CREATE) &&
              <li>
                <Link
                  href={routes.user.create}
                  className="uk-icon"
                  data-uk-icon="icon: plus"
                  data-uk-tooltip={'Add'}
                />
              </li>
            }
          </ul>
        </div>

        <div className="uk-card-body uk-overflow-auto">
          <table className="uk-table uk-table-divider uk-table-striped uk-table-hover uk-table-small uk-table-middle uk-table-responsive">
            <thead>
              <tr>
                <th className="uk-table-shrink">{'id'}</th>
                <th className="uk-table-shrink">{'Email'}</th>
                <th className="uk-table-expand">{'Name'}</th>
                {isGranted(GLOBAL_CONTEXT.ACCESS) &&
                  <th className="uk-table-shrink">{'Organisation'}</th>
                }
                <th className="uk-table-shrink">{'Roles'}</th>
                <th className="uk-table-shrink">{'Language'}</th>
                <th className="uk-table-shrink">{'State'}</th>
                <th className="uk-table-shrink">{'Actions'}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) =>
                <tr key={item.id}>
                  {/** Id */}
                  <td>{item.id}</td>
                  {/** Email */}
                  <td>{item.email}</td>
                  {/** Name */}
                  <td>{item.name}</td>
                  {/** Organisation */}
                  {isGranted(GLOBAL_CONTEXT.ACCESS) &&
                    <td>{findOrganisation(item.organisationId)?.name ?? '-'}</td>
                  }
                  {/** Role */}
                  <td>
                    {item.roles.map(role =>
                      <span
                        key={role}
                        className="uk-label"
                      >{role}</span>
                    )}
                  </td>
                  {/** Language */}
                  <td>{item.language}</td>
                  {/** State */}
                  <td><State state={item.state} /></td>
                  {/** Actions */}
                  <td>
                    <fieldset
                      className="uk-fieldset uk-text-right uk-text-left@m"
                      disabled={isDeleting}
                    >
                      <div className="uk-button-group">
                        {/** Read */}
                        {isGranted(PERMISSION.READ) &&
                          <Link
                            href={routes.user.read.replace(':id', item.id.toString())}
                            className="uk-button uk-button-primary uk-button-small"
                            data-uk-tooltip="title: View"
                          >
                            <Icon icon="eye" />
                          </Link>
                        }
                        {/** Update */}
                        {isGranted(PERMISSION.UPDATE) &&
                          <Link
                            href={routes.user.update.replace(':id', item.id.toString())}
                            className="uk-button uk-button-primary uk-button-small"
                            data-uk-tooltip="title: Edit"
                          >
                            <Icon icon="pencil" />
                          </Link>
                        }
                        {/** Sign in as */}
                        {isGranted(PERMISSION_AUTH.SIGN_IN_AS) && item.id !== user.id &&
                          <button
                            className="uk-button uk-button-default uk-button-small"
                            type="button"
                            disabled={isAuthLoading}
                            data-uk-tooltip={'title: Sign-in as this user'}
                            onClick={() => handleSignInAsClick(item)}
                          >
                            <Icon icon="sign-in" />
                          </button>
                        }
                        {/** Delete */}
                        {isGranted(PERMISSION.DELETE) && item.id !== user.id &&
                          <button
                            className="uk-button uk-button-danger uk-button-small"
                            type="button"
                            data-uk-tooltip="title: Delete"
                            onClick={() => handleDeleteClick(item)}
                          >
                            <Icon icon="trash" />
                          </button>
                        }
                      </div>
                    </fieldset>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/** Pagination */}
        <div className="uk-card-footer">
          <Pagination
            pageIndex={pageIndex}
            pageItems={items.length}
            paginationLimit={paginationLimit}
            setPageIndex={setPageIndex}
          />
        </div>

        {/** Overlays */}
        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading}
        />

      </div>
    </section>
  )
}

export default withPagePermissions(UsersIndex, [PERMISSION.INDEX])
