/**
 * TODO: Evaluate using cancellable requests: https://github.com/vercel/swr/issues/129
 */
import useSWR from 'swr'

import { useState } from 'preact/hooks'
import { wrappedFetch } from './helpers.js'
import { useApiConfig } from '../../Context/ApiConfigProvider.jsx'
import { useAuth } from '../../Context/AuthProvider.jsx'

/**
 * @typedef { import('swr').SWRResponse["mutate"] } SWRMutate
 * @typedef { import('swr').SWRConfiguration<any, ConnectionError | AbstractHttpError> } SWRConfiguration
 * @typedef { import('../../Error/ConnectionError.js').default } ConnectionError
 * @typedef { import('../../Error/AbstractHttpError.js').default } AbstractHttpError
 */

/**
 * Fetcher
 * @return {(key: [string, number]) => Promise<App.Entity.EntityInterface|App.Entity.EntityInterface[], Error>}
 */
export function useFetcher() {
  const { baseUrl } = useApiConfig()
  const { accessToken } = useAuth()

  /**
   * @param {[string, number]} key - [path, auth user Id]
   * @note the '/v1' suffix is part of baseUrl (not path), so cannot create using 'new URL(path, baseUrl)'
   */
  function fetcher([path, _authUserId]) {
    const url = `${baseUrl}${path}`

    return wrappedFetch(url, {
      method: 'GET',
    }, accessToken)
  }

  return fetcher
}

/**
 * @template {App.Entity.EntityInterface} Item
 * @typedef IndexResponse
 * @property {Item[]} items
 * @property {Error} [error]
 * @property {boolean} isLoading
 * @property {SWRMutate} mutate
 */

/**
 * Index
 * @param {string} resourcePath
 * @param {boolean} [revalidate]
 * @param {SWRConfiguration} [config]
 * @param {boolean} [shouldFetch]
 * @return {IndexResponse<any>}
 */
export function useIndex(resourcePath, revalidate = true, config = undefined, shouldFetch = true) {
  const { user: authUser } = useAuth()

  const { data, error, isLoading, mutate } = useSWR(authUser && shouldFetch && [resourcePath, authUser.id], {
    revalidateOnFocus: revalidate,
    revalidateOnMount: revalidate,
    ...config,
  })

  return {
    items: data,
    error,
    isLoading,
    mutate,
  }
}

/**
 * @template {App.Entity.EntityInterface} Item
 * @typedef ReadResponse
 * @property {Item} item
 * @property {Error} [error]
 * @property {boolean} isLoading
 * @property {SWRMutate} mutate
 */

/**
 * Read
 * @param {string} resourcePath
 * @param {number|null} id
 * @param {boolean} revalidate
 * @return {ReadResponse<any>}
 */
export function useRead(resourcePath, id, revalidate = true) {
  const { user: authUser } = useAuth()

  const key = id
    ? `${resourcePath}/${id}`
    : resourcePath

  const { data, error, isLoading, mutate } = useSWR(authUser && [key, authUser.id], {
    revalidateOnFocus: revalidate,
    revalidateOnMount: revalidate,
  })

  return {
    item: data,
    error,
    isLoading,
    mutate,
  }
}

/**
 * @template {App.Entity.EntityInterface} Item
 * @typedef CreateResponse
 * @property {Error|undefined} error
 * @property {boolean} isCreating
 * @property {Modifier<Item>} creator
 */

/**
 * Create
 * @param {string} resourcePath
 * @return {CreateResponse<any>}
 */
export function useCreate(resourcePath) {
  const { error, isMutating: isCreating, trigger: creator } = useModifier(resourcePath, null, 'POST')

  return {
    error,
    isCreating,
    creator,
  }
}

/**
 * @template {App.Entity.EntityInterface} Item
 * @typedef UpdateResponse
 * @property {Error|undefined} error
 * @property {boolean} isUpdating
 * @property {Modifier<Item>} updater
 */

/**
 * Update
 * @param {string} resourcePath
 * @param {number|null} id
 * @return {UpdateResponse<any>}
 */
export function useUpdate(resourcePath, id) {
  const { error, isMutating: isUpdating, trigger: updater } = useModifier(resourcePath, id, 'PUT')

  return {
    error,
    isUpdating,
    updater,
  }
}

/**
 * @template {App.Entity.EntityInterface} Item
 * @typedef DeleteResponse
 * @property {Error|undefined} error
 * @property {boolean} isDeleting
 * @property {Modifier<Item>} deleter
 */

/**
 * Delete
 * @param {string} resourcePath
 * @param {number|null|undefined} id
 * @return {DeleteResponse<any>}
 */
export function useDelete(resourcePath, id) {
  const { error, isMutating: isDeleting, trigger: deleter } = useModifier(resourcePath, id, 'DELETE')

  return {
    error,
    isDeleting,
    deleter,
  }
}


/**
 * Helper type definition
 * @template {App.Entity.EntityInterface} Item
 * @callback Modifier
 * @param {Item} item
 * @return {Promise<Item>}
 */

/**
 * useSWRMutation-like modifier for POST, PUT and DELETE
 * @param {string} path
 * @param {number|null|undefined} id - Null to not use ID in URL, undefined to resolve from item
 * @param {string} method
 * @return {{
 *   error: Error|undefined,
 *   isMutating: boolean,
 *   trigger: Modifier<App.Entity.EntityInterface>,
 * }}
 */
function useModifier(path, id, method) {
  const { baseUrl } = useApiConfig()
  const { accessToken } = useAuth()

  const [ error, setError ] = useState(undefined)
  const [ isMutating, setIsMutating ] = useState(false)

  /**
   * @param {App.Entity.EntityInterface} item
   * @return {Promise<any>}
   */
  const trigger = (item) => {
    setError(undefined)
    setIsMutating(true)

    const url = id === null
      ? `${baseUrl}${path}`
      : `${baseUrl}${path}/${id ?? item.id}`

    return wrappedFetch(url, {
      method,
      body: JSON.stringify(item),
    }, accessToken)
      .catch(setError)
      .finally(() => setIsMutating(false))
  }

  return {
    error,
    isMutating,
    trigger,
  }
}
